
import { defineComponent, ref, onMounted, computed, watch, Ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import { Edit, Check, Delete, Close } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { event } from "vue-gtag";
import { ElMessage, ElMessageBox } from "element-plus";

interface InitSelectData {
  module: string;
  trimestre: string;
  sousModule?: string;
}
interface addAppBody {
  module: string;
  student: string;
  appreciation: string;
  trimester: string;
  classroom: string;
  type: string;
  sousModule?: string;
}
interface getAppQuery {
  module: string;
  trimester: string;
  classroom: string;
  type: string;
  sousModule?: string;
  schoolarYear: string;
  teacher: string;
}
interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
  photo: string;
}
interface ISubject {
  _id: string;
  name: string;
  status: string;
}

interface IModule {
  _id?: string;
  name?: string;
  status: string;
  subjects: ISubject[];
}
interface ModuleAccess {
  _id: string;
  name: string;
  type: string;
  status: string;
}

interface SousModuleAccess {
  _id: string;
  name: string;
  status: string;
}

interface ModuleDataAccess {
  module: ModuleAccess;
  sousModules: SousModuleAccess[];
  haveSubMod: boolean;
  canAppModule: boolean;
}
interface Appreciation {
  _id: string;
  appreciation: string;
  schoolarYear: string;
  student: string;
  module: string;
  trimester: number;
  moduleName?: string;
}
interface SubSubjectMarkData {
  name: string;
  maxMark: string;
  mark: string;
}
interface SubjectMarkData {
  submoduleID?: string;
  coef: number;
  name: string;
  _id: string;
  mark: string;
  subSubjectMarks?: SubSubjectMarkData[];
}
interface StudentSubModuleMark {
  _id: string;
  label: string;
  appreciation: string;
  subjects: SubjectMarkData[];
  NbSubjects: number;
  moyenne: string;
}
interface ModuleMarkData {
  coef: number;
  name: string;
  _id: string;
  subjects: SubjectMarkData[];
  moyenne: string;
  hasSubModules: boolean;
  submodules: StudentSubModuleMark[];
}

interface StudentMarkData {
  id: string;
  fullName: string;
  gender: string;
  classroomName: string;
  classroomLevel: number;
  students: number;
  moyenne: string;
  modules: ModuleMarkData[];
}
export default defineComponent({
  name: "appreciations",
  props: {
    widgetClasses: String,
    type: {
      type: String,
      required: true,
    },
    title: String,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const filter = ref<string>("");
    const list = ref<IStudent[]>([]);
    const studentsMarkData = ref<StudentMarkData[]>([]);
    const listAppAccess = ref<ModuleDataAccess[]>([]);
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");
    let listSubject: any[] = [];

    const selectRef = ref();
    const isEditing = ref(false);
    const unsavedChanges = ref(false);
    const sy = window.localStorage.getItem("activeSchoolarYear");
    const trimester = JwtService.getTrimester();
    const loading = ref(true);

    const targetData = ref<InitSelectData>({
      module: "",
      trimestre: trimester || "1",
    });
    const rules = ref({
      matiere: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
      trimestre: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
    });
    const filtredStudents = computed(() => {
      if (filter.value) {
        return list.value.filter((s: IStudent) => {
          let name: string = s.firstName + " " + s.lastName;
          return name.includes(filter.value.toString());
        });
      }
      return list.value;
    });
    const getMoyenne = (studentID: string) => {
      const studentMarkData = studentsMarkData.value.find(
        (smd) => smd.id == studentID
      );
      if (studentMarkData) return studentMarkData.moyenne;

      return "--";
    };

    const getStudentData = (studentID: string) => {
      const student: StudentMarkData | undefined = studentsMarkData.value.find(
        (sd) => sd.id == studentID
      );
      return student ? student.modules[0].subjects : [];
    };

    const activeSousModules = computed(() => {
      let activeModule = listAppAccess.value.find(
        (m) => m.module._id == targetData.value.module
      );
      if (!activeModule) return false;
      if (activeModule.canAppModule) return false;
      if (activeModule.sousModules && activeModule.sousModules.length == 0)
        return false;
      return activeModule.sousModules;
    });

    const appreciations = ref<Appreciation[]>([]);

    const selectedStudent = ref("");
    const currentAppreciation = ref("");
    const saveLoading = ref(false);
    const moduleMoyennes = ref<any[]>([]);
    const manualAppreciation = ref<boolean>(false);
    const appreciationOptions = ref<any[]>([]);

    const getAppreciation = (studentID: string) => {
      const appreciation = appreciations.value.find(
        (a: Appreciation) =>
          a.student == studentID &&
          a.module == targetData.value.module &&
          String(a.trimester) == String(targetData.value.trimestre)
      );
      if (appreciation) return appreciation.appreciation;
      return "";
    };

    const handleEdit = (studentID: string) => {
      selectedStudent.value = studentID;
      currentAppreciation.value = getAppreciation(studentID);
    };

    const handleCloseEdit = () => {
      selectedStudent.value = "";
      currentAppreciation.value = "";
      isEditing.value = false;
      unsavedChanges.value = false;
    };

    const deleteLoading = ref(false);

    const handleDelete = (studentID) => {
      event("Delete Appreciation", {
        event_category: "Appreciations",
        event_label: "Classe profile",
        value: 1,
      });

      const appreciation = appreciations.value.find(
        (a: Appreciation) =>
          a.student == studentID && a.module == targetData.value.module
      );

      deleteLoading.value = true;
      ApiService.delete("/bulletin/v2/appreciation/" + appreciation?._id)
        .then(() => {
          appreciations.value = appreciations.value.filter(
            (app) => app._id != appreciation?._id
          );
        })
        .finally(() => {
          deleteLoading.value = false;

          Swal.fire({
            text: t("appreciation.delete"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    };

    const handleSave = async (studentID: string) => {
      event("Save Appreciation", {
        event_category: "Appreciations",
        event_label: "Classe profile",
        value: 1,
      });

      saveLoading.value = true;

      const teacherApp = teacherAppreciations.value.find(
        (el) => currentAppreciation.value == el.appreciation
      );
      if (!teacherApp && teacherAppreciations.value.length < 15)
        await ApiService.put("/teacherAppreciation", {
          data: {
            teacher: store.getters.currentUser._id as string,
            appreciation: currentAppreciation.value,
          },
        })
          .then(({ data }) => {
            teacherAppreciations.value.push(data);
          })
          .catch((e) => console.log(e));

      const appreciation = appreciations.value.find(
        (a: Appreciation) =>
          a.student == studentID &&
          a.module == targetData.value.module &&
          a.trimester == Number(targetData.value.trimestre)
      );

      if (!appreciation) {
        let body: addAppBody = {
          module: targetData.value.module,
          student: selectedStudent.value,
          appreciation: currentAppreciation.value,
          trimester: targetData.value.trimestre,
          classroom: String(route.params.id),
          type: props.type,
        };
        if (activeSousModules.value && targetData.value.sousModule)
          body.sousModule = targetData.value.sousModule;
        ApiService.put("/bulletin/v2/appreciation/", {
          data: body,
        })
          .then(({ data }) => {
            appreciations.value.push(data);
            Swal.fire({
              text: t("appreciation.saved"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .finally(() => {
            saveLoading.value = false;
            handleCloseEdit();
          });
      } else
        ApiService.patch("/bulletin/v2/appreciation/" + appreciation._id, {
          data: {
            appreciation: currentAppreciation.value,
          },
        })
          .then(() => {
            appreciation.appreciation = currentAppreciation.value;
          })
          .finally(() => {
            saveLoading.value = false;
            unsavedChanges.value = false;
            handleCloseEdit();

            Swal.fire({
              text: t("appreciation.saved"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
    };

    onMounted(async () => {
      //GET STUDENTS
      ApiService.setHeader();
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = route.params.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: { $arrayElemAt: ["$classRoom._id", 0] },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              teachers: {
                $arrayElemAt: ["$classRoom.teachers", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              firstNameLower: { $toLower: "$firstName" },
            },
          },
          {
            $sort: {
              firstNameLower: 1,
            },
          },
        ],
      }).then(({ data }) => {
        list.value = data;
      });

      ApiService.post("/bulletin/v2/appreciation/filter", {
        query: {
          schoolarYear: sy,
          trimester: targetData.value.trimestre || "1",
          classroom: route.params.id,
          teacher: store.getters.currentUser._id,
          type: props.type,
        },
      }).then(({ data }) => {
        appreciations.value = data as Array<Appreciation>;
      });

      //GET TEACHER
      ApiService.setHeader();
      await ApiService.post("/teacherAccess/appreciation", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: targetData.value.trimestre || "1",
          classroom: route.params.id,
          type: props.type,
        },
      })
        .then(({ data }) => {
          listAppAccess.value = data;
        })
        .catch((e) => console.log(e));

      await ApiService.get(
        `/mark/moduleMoyennes/${route.params.id}/${
          targetData.value.trimestre || "1"
        }`
      )
        .then(({ data }) => {
          moduleMoyennes.value = data;
        })
        .catch((e) => console.log(e));

      await ApiService.post(
        "/buildings/filter",
        {
          query: {
            dbName: store.getters.serverConfigUrl?.dbName,
          },
        }, //dbName:"school2"
        {
          headers: { db: "Root" },
        }
      ).then(({ data }) => {
        const _data = data[0];
        manualAppreciation.value = _data.manualAppreciation;
        appreciationOptions.value = [..._data.appreciationOptions];
        loading.value = false;
      });
    });

    watch(
      targetData,
      async () => {
        loading.value = true;
        let query: getAppQuery = {
          schoolarYear: String(sy),
          trimester: targetData.value.trimestre || "1",
          classroom: String(route.params.id),
          teacher: store.getters.currentUser._id,
          module: targetData.value.module,
          type: props.type,
        };
        if (activeSousModules.value && targetData.value.sousModule)
          query.sousModule = targetData.value.sousModule;
        await ApiService.post("/bulletin/v2/appreciation/filter", {
          query: query,
        }).then(({ data }) => {
          appreciations.value = data as Array<Appreciation>;
        });

        await ApiService.post(
          `/bulletin/v2/stats/teacher/${targetData.value.trimestre || "1"}/${
            route.params.id
          }`,
          {
            query: {
              moduleList: [targetData.value.module],
              type: props.type,
            },
          }
        )
          .then(({ data }) => {
            studentsMarkData.value = data;
          })
          .catch((e) => console.log(e));
        loading.value = false;
      },
      { deep: true }
    );

    const dialogFormVisible = ref(false);
    const teacherApreciationText = ref("");
    const loadingAddTeacherAppreciation = ref(false);
    const teacherAppreciations = ref<
      { _id: string; appreciation: string; teacher: string }[]
    >([]);
    const loadingEditTeacherAppreciation = ref(false);
    const selectedTeacherAppreciation = ref<any>("");
    const currentTeacherAppreciation = ref<any>("");

    ApiService.post("/teacherAppreciation/filter", {
      query: {
        teacher: store.getters.currentUser._id,
      },
    }).then(({ data }) => {
      data.forEach((el) => {
        teacherAppreciations.value.push(el);
      });
    });

    const handleAddAppTeacher = () => {
      event("Add appreciation option", {
        event_category: "Appreciation options",
        event_label: "Classe profile",
        value: 1,
      });

      loadingAddTeacherAppreciation.value = true;
      ApiService.put("/teacherAppreciation", {
        data: {
          teacher: store.getters.currentUser._id as string,
          appreciation: teacherApreciationText.value,
        },
      })
        .then(({ data }) => {
          teacherAppreciations.value.push(data);
          teacherApreciationText.value = "";
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loadingAddTeacherAppreciation.value = false;
        });
      teacherApreciationText.value = "";
    };

    const openEditTeacherAppreciation = (record) => {
      currentTeacherAppreciation.value = record.appreciation;
      selectedTeacherAppreciation.value = record._id;
    };

    const handleEditAppTeacher = (record) => {
      event("Edit appreciation option", {
        event_category: "Appreciation options",
        event_label: "Classe profile",
        value: 1,
      });

      loadingEditTeacherAppreciation.value = true;
      ApiService.patch("/teacherAppreciation/" + record._id, {
        data: {
          appreciation: currentTeacherAppreciation.value,
        },
      })
        .then(() => {
          record.appreciation = currentTeacherAppreciation.value;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loadingEditTeacherAppreciation.value = false;
          selectedTeacherAppreciation.value = "";
          currentTeacherAppreciation.value = "";
        });
    };

    const loadingDeleteTeacherAppreciation = ref(false);
    const handleDeleteAppTeacher = (record) => {
      event("Delete appreciation option", {
        event_category: "Appreciation options",
        event_label: "Classe profile",
        value: 1,
      });

      loadingDeleteTeacherAppreciation.value = true;
      ApiService.delete("/teacherAppreciation/" + record._id)
        .then(() => {
          teacherAppreciations.value = teacherAppreciations.value.filter(
            (el: any) => el._id !== record._id
          );
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loadingDeleteTeacherAppreciation.value = false;
        });
    };

    const onFliterSelect = (value) => {
      if (value) currentAppreciation.value = value;
    };
    const handleModuleChange = () => {
      //default select first sousModule if exists
      console.log(currentAppreciation.value);
      console.info(targetData.value);
      if (activeSousModules.value)
        targetData.value.sousModule = activeSousModules.value[0]._id;
      else targetData.value.sousModule = "";
    };
    const getTableLayout = (studentID: string) => {
      const student: StudentMarkData | undefined = studentsMarkData.value.find(
        (sd) => sd.id == studentID
      );
      if (!student) return [];
      const module = student.modules[0];
      const targetSubModule = module.submodules.find(
        (sm) => sm._id == targetData.value.sousModule
      );
      if (targetSubModule) {
        const subModuleID = targetSubModule._id;
        const subModuleMoy = targetSubModule.moyenne;
        const subModuleName = targetSubModule.label;
        let subjects = module.subjects
          .filter((s) => s.submoduleID == subModuleID)
          .map((subject) => ({
            subject: subject.name,
            mark: subject.mark,
          }));
        subjects.push({
          subject: "Moyenne",
          mark: subModuleMoy,
        });
        return {
          key: subModuleID + module._id,
          submodule: subModuleName,
          subModuleMark: subModuleMoy,
          subjects: subjects,
        };
      }
    };
    const unsavedChangesAlert = (val, type) => {
      ElMessageBox.confirm(
        t("appreciation.unsavedchangesContent"),
        t("appreciation.unsavedchanges"),
        {
          confirmButtonText: t("punishments.yes"),
          cancelButtonText: t("punishments.cancel"),
          type: "warning",
        }
      ).then(() => {
        if (type == "module") {
          targetData.value.module = val;
          handleModuleChange();
        }
        if (type == "trimester") {
          targetData.value.trimestre = val;
        }
        if (type == "sousModule") {
          targetData.value.sousModule = val;
        }
        handleCloseEdit();
      });
    };
    return {
      getTableLayout,
      openEditTeacherAppreciation,
      currentTeacherAppreciation,
      selectedTeacherAppreciation,
      loadingEditTeacherAppreciation,
      loadingDeleteTeacherAppreciation,
      handleAddAppTeacher,
      teacherAppreciations,
      teacherApreciationText,
      loadingAddTeacherAppreciation,
      handleEditAppTeacher,
      handleDeleteAppTeacher,
      dialogFormVisible,
      saveLoading,
      currentAppreciation,
      handleEdit,
      handleSave,
      handleDelete,
      deleteLoading,
      handleCloseEdit,
      selectedStudent,
      getAppreciation,
      t,
      targetData,
      rules,
      list,
      onMounted,
      listAppAccess,
      filter,
      filtredStudents,
      apiUrl,
      Check,
      Edit,
      Delete,
      Close,
      loading,
      getMoyenne,
      manualAppreciation,
      appreciationOptions,
      onFliterSelect,
      getStudentData,
      activeSousModules,
      handleModuleChange,
      isEditing,
      unsavedChanges,
      unsavedChangesAlert,
    };
  },
});
